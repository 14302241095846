<template>
  <b-card no-body>
    <div class="px-3 py-2">
      <div class="float-end">
        <b-button
          v-on:click="
            () => {
              download = 1;
            }
          "
          class="float-right w-25"
          variant="primary"
        >
          {{ $t("navigation.downloadreport") }}
        </b-button>
      </div>
      <h3>{{ $t("navigation.fixeddeposits") }}</h3>
    </div>

    <b-table
      ref="refDataListTable"
      :items="fetchData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #cell(holdername)="data">
        <b-link
          :to="{
            name: 'investment-fixeddeposits-view',
            params: { id: data.item.id },
          }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.holdernamedata?data.item.holdernamedata.data.name||'':null }}
        </b-link>
      </template>

      <template #cell(ifsccode)="data">
        <span>
          {{ data.item.data.ifsccode || "" }}
        </span>
      </template>
      <template #cell(bankname)="data">
        <span>
          {{ data.item.data.bankname || "" }}
        </span>
      </template>
      <template #cell(branch)="data">
        <span>
          {{ data.item.data.branch || "" }}
        </span>
      </template>
      <template #cell(amountinvested)="data">
        <span
                style="font-family: rupee; font-weight: bold; font-size: 17px"
                class="fw-bolder"
                >&#8377;</span
              >
        <span>
          {{ data.item.data.amountinvested || 0 }}
        </span>
      </template>
      <template #cell(accountnumber)="data">
        <span>
          {{ data.item.data.accountnumber || "" }}
        </span>
      </template>
      <template #cell(maturityvalue)="data">
        <span>
          {{ data.item.data.maturityvalue || 0 }}
        </span>
      </template>
      <template #cell(rateofintrest)="data">
        <span>
          {{ data.item.data.rateofintrest || 0 }}
        </span>
      </template>
      <template #cell(startdate)="data">
        <span>
          {{ data.item.data.startdate || "" }}
        </span>
      </template>
      <template #cell(maturitydate)="data">
        <span>
          {{ data.item.data.maturitydate || "" }}
        </span>
      </template>
      <template #cell(nomineename)="data">
        <span>
          {{ data.item.data.nomineename || "" }}
        </span>
      </template>


    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormDatepicker,
  BFormInput,
  VBTooltip,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BFormSelect,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import useList from "./useList";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormSelect,
    BFormDatepicker,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BPagination,
    flatPickr,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "reports-investment-fixeddeposits";
    const modulename = "Fixed Deposits";
    const modulenamesub = "fixeddeposits";
    // Register module
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,
      selectedoptions,
      selectopt,
      fromdatex,
      todatex,
      resolveDataRoleVariant,
      download,
      xoptions,
      xvalue,
      yvalue,
      xname,
      yname,
      category,
      changetype,
    } = useList();
    return {
      fetchData,
      GENAPP_APP_STORE_MODULE_NAME,
      download,
      modulename,
      tableColumns,
      fromdatex,
      todatex,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      selectedoptions,
      isSortDirDesc,
      selectopt,
      refDataListTable,
      fromdatex,
      todatex,
      xoptions,
      avatarText,
      resolveDataRoleVariant,
      xvalue,
      yvalue,
      xname,
      yname,
      category,
      changetype,
    };
  },
  data() {
    return {
      key: "",
    };
  },
  watch: {
    xvalue(oldval, newval) {
      this.key = Math.random();
    },
    yvalue(oldval, newval) {
      this.key = Math.random();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.mutualfund-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
canvas {
  width: 100%;
  height: 100%;
  display: block;
}

div.chart-container {
  padding: 1px;
}
</style>
